import { registerApplication, start, navigateToUrl } from "single-spa";
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from "single-spa-layout";

const createProtectedRoute = (rootPath: string, appName: string) => {
  return {
    type: "route",
    path: rootPath,
    routes: [
      {
        type: "div",
        attrs: [
          {
            name: "id",
            value: "sidebar",
          },
        ],
        routes: [
          {
            type: "application",
            name: "@teppixdigital/app-sidebar",
          },
        ],
      },
      {
        type: "div",
        attrs: [
          {
            name: "id",
            value: "app",
          },
        ],
        routes: [
          {
            type: "application",
            name: appName,
          },
        ],
      },
    ],
  };
};

const createPublicRoute = (path: string, appName: string) => {
  return {
    type: "route",
    path: path,
    routes: [
      {
        type: "application",
        name: appName,
      },
    ],
  };
};

const initRoute = () => {
  const routes = constructRoutes({
    mode: "history",
    base: "/",
    disableWarnings: false,
    containerEl: "#container",
    routes: [
      // createPublicRoute("/auth", "@teppixdigital/app-auth"),
      createPublicRoute("/posts", "@teppixdigital/app-content-client"),
      // createPublicRoute("/registration", "@teppixdigital/app-registration"),
    ],
  });
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  applications.forEach(registerApplication);
  constructLayoutEngine({ routes, applications, active: true });
};

(() => {
  window.addEventListener("on_unhandled_request_error", ((
    event: Event & { detail: { status_code?: number } }
  ) => {
    if (403 === event?.detail?.status_code) {
      navigateToUrl("/auth/login");
    }
  }) as EventListener);
  initRoute();
  start();
})();
